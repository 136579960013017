import { onMounted,nextTick, defineComponent, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import SideMenu from '@/components/layout/SideMenu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';



export default defineComponent({
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideMenu,
	    SideComponent,
	    Loading,
	    
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    let type = route.query.type?route.query.type:0;
	    let id = route.query.id?route.query.id:0;
		const data = reactive({
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			activeName:'first',
			picFiles: '',
			isPicLoading: false,
			isVideoLoading: false,
			isImgSingleLoading: false,
			isVideoSingleLoading: false,
			imgInfo: [],
			videoInfo: [],
			filesInfo: [],
			formSize: 'default',
			topicList:[
				
			],
			subjectList: [],
			pagination:{},
			page:1,
			keywords:'',
			allCats:[],
			showSubject: false,
			subtype: 1,
			nomore:true,
			showImgUpload:true,
			showPicForm: false,
			showVideoUpload:true,
			showVideoForm: false,
			hasImagePermissions: false,
			hasVideoPermissions: false,
			disabled: false,
			isMobile: false,
			ImagePackages:[],
			VideoPackages:{},
			search:'',
			tag: '',
      		tags: [],
      		id:0,
      		picTags:[],
			pic_keyword:'',
			videoTags:[],
			video_keyword:'',
			pic_disabled:false,
			video_disabled:false,
			storageKeyPic: 'keywords_pic',
			storageKeyVideo: 'keywords_video',
		});
		if(id && type){
			data.subtype = type;
			data.id = id;
			if(type==1){
				data.activeName = 'first';
			}else if(type==2){
				data.activeName = 'second';
			}
		}
		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};
		const labelPosition = ref('top');
		// bus.emit('needRefresh', true)
		const radio = ref(3)
		const store = useStore();	
		const picOneRef = ref()
		const picOneForm = reactive({			
  			description:'',
  			keywords: [],
  			subject_id:'',
  			package_id: '',
  			year:'',
  			package_id:'',
		})
		let pic_keywords = localStorage.getItem(data.storageKeyPic);
		console.log(pic_keywords,"pic_keyword");
		if(pic_keywords){
			pic_keywords = JSON.parse(pic_keywords);
			console.log(pic_keywords,"pic_keyword_parse");
			picOneForm.description = pic_keywords.description;
			picOneForm.keywords = pic_keywords.keywords;
			picOneForm.subject_id = pic_keywords.subject_id;
			picOneForm.package_id = pic_keywords.package_id;
			picOneForm.year = pic_keywords.year;
		}
		

		const videoOneRef = ref()
		const videoOneForm = reactive({		
  			description:'',
  			keywords: [],

		})
		let video_keywords = localStorage.getItem(data.storageKeyVideo);
		if(video_keywords){
			video_keywords = JSON.parse(video_keywords);
			videoOneForm.description = video_keywords.description;
			videoOneForm.keywords = video_keywords.keywords;
			
		}

		const ruleRef = ref()
		const ruleForm = reactive({
			tableData:[{
				title:'',			
	  			description:'',
	  			keywords: [],
	  			subject_id:'',
	  			package_id: '',	  			
	  			thumb: '',	
	  			pic_keyword:'',
	  			year:'',
	  			hasSimilar: false,
			}]
		})
		
		const _rules = reactive({
			title: [
				{ required: true, message: proxy.$t('apply_work.tip6'), trigger: 'blur' },
				
			],
			subject_id: [
				{ required: true, message: proxy.$t('apply_work.tip12'), trigger: 'blur' },
				
			],
			package_id: [
				{ required: true, message: proxy.$t('apply_work.select_price_pack'), trigger: 'blur' },
				
			],
			// origin_status: [
			// 	{ required: true, message: '请选择原图状态', trigger: 'blur' },
				
			// ],
			// origin_price: [
			// 	{ required: true, message: proxy.$t('tip19'), trigger: 'blur' },
				
			// ],
			// min_price: [
			// 	// { required: true, message: '请填写原图价格', trigger: 'blur' },
				
			// ],
			// max_price: [
			// 	// { required: true, message: '请填写原图价格', trigger: 'blur' },
				
			// ],
			
		})

		// const hancleMinChange = (value) => {
		// 	console.log(value,"value")
		// 	if(value=='1'){
		// 		_rules.min_price = [
		// 			{ required: true, message: proxy.$t('tip16'), trigger: 'blur' },
		// 		]
		// 	}
		// }
		// const hancleOriginChange = (value) => {
		// 	console.log(value,"value")
		// 	if(value=='1'){
		// 		_rules.origin_price = [
		// 			{ required: true, message: proxy.$t('tip19'), trigger: 'blur' },
		// 		]
		// 	}
		// }
		// const hancleMaxChange = (value) => {
		// 	console.log(value,"value")
		// 	if(value=='1'){
		// 		_rules.max_price = [
		// 			{ required: true, message: proxy.$t('tip22'), trigger: 'blur' },
		// 		]
		// 	}
		// }

		const submitForm = (formEl) => {
			console.log(ruleForm.tableData,"tableData");
			
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.pic_disabled = true;
			formEl.validate((valid, fields) => {
			    if (valid) {
				    console.log(valid,'submit!')
				    let arr = [];
				    let has = false;
				    ruleForm.tableData.forEach((item,index)=>{
				    	if(item.hasSimilar){
				    		has = true;
				    	}
				    	let obj = {
				    		title: item.title,
				    		description: item.description,
				    		keywords: item.keywords,
				    		subject_id: item.subject_id,
				    		package_id: item.package_id,
				    		thumb: item.thumb,
				    		year:item.year,
				    	}
				    	arr.push(obj);

				    })
				    console.log(arr,"arr")
				    if(has){
				    	ElMessage({
						    message: proxy.$t('apply_work.has_similar'),
						    grouping: true,
						    type: 'error',
						})
						data.pic_disabled = false;
				    }else{

					    if(id>0 && type==1){
					    	store.dispatch("user/CameraWorkUpdate",{id:id,body:arr[0]}).then((res)=>{
						      	console.log("CameraWorkUpdate",res);
						      	
						      	ElMessage({
								    message: proxy.$t('apply_common.upload_success'),
								    grouping: true,
								    type: 'success',
								})
						      	router.push({path:'/my/work_manage',query:{type: data.subtype}})
						    }).catch(err => {
					            // console.error(err)
					            ElMessage({
								    message: err.message,
								    grouping: true,
								    type: 'error',
								})
								data.pic_disabled = false;
					        })
					    }else{
					    	store.dispatch("user/PostImgdata",arr).then((res)=>{
						      	console.log("PostImgdata",res);					      	
						      	ElMessage({
								    message: proxy.$t('apply_common.upload_success'),
								    grouping: true,
								    type: 'success',
								})
								router.push({path:'/my/work_manage',query:{type: data.subtype}})
						    }).catch(err => {
					            // console.error(err)
					            ElMessage({
								    message: err.message,
								    grouping: true,
								    type: 'error',
								})
								data.pic_disabled = false;
					        })
					    }
				    }
			    } else {
			      console.log('error submit!')
			      data.pic_disabled = false;
			      return false
			    }
			})
		}


		const ruleVideoRef = ref()
		const ruleVideoForm = reactive({
			tableData:[{
				title:'',			
	  			description:'',
	  			keywords: [],
	  			thumb: '',	
	  			url:'',
	  			video_keyword:'',

			}]
		})

		const video_rules = reactive({
			title: [
				{ required: true, message: proxy.$t('apply_work.tip31'), trigger: 'blur' },
				
			],
			
		})

		const submitVideoForm = (formEl) => {
			console.log(ruleVideoForm.tableData,"ruleVideoForm");
			
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.video_disabled = true;
			formEl.validate((valid, fields) => {
			    if (valid) {
				    // console.log(valid,'submit!')
				    let arr = [];
				    ruleVideoForm.tableData.forEach((item,index)=>{
				    	
				    	let obj = {
				    		title: item.title,
				    		description: item.description,
				    		keywords: item.keywords,
				  			thumb: item.thumb,
				  			url: item.url,

				    	}
				    	arr.push(obj);
				    })
				    // console.log(arr,"videoarr")
				    if(id>0 && type==2){
				    	store.dispatch("user/CameraWorkUpdate",{id:id,body:arr[0]}).then((res)=>{
					      	console.log("CameraWorkUpdate",res);
					      	ElMessage({
							    message: proxy.$t('apply_common.upload_success'),
							    grouping: true,
							    type: 'success',
							})
					      	router.push({path:'/my/work_manage',query:{type: data.subtype}})
					    }).catch(err => {
				            // console.error(err)
				            ElMessage({
							    message: err.message,
							    grouping: true,
							    type: 'error',
							})
							data.video_disabled = false;
				        })
				    }else{
				    	store.dispatch("user/PostVideoData",arr).then((res)=>{
					      	console.log("PostVideoData",res);
					      	ElMessage({
							    message: proxy.$t('apply_common.upload_success'),
							    grouping: true,
							    type: 'success',
							})
					      	router.push({path:'/my/work_manage',query:{type: data.subtype}})
					    }).catch(err => {
				            // console.error(err)
				            ElMessage({
							    message: err.message,
							    grouping: true,
							    type: 'error',
							})
							data.video_disabled = false;
				        })
				    }
			    	
				    
				    
			    } else {
			      console.log('error submit!')
			      data.video_disabled = false;
			      return false
			    }
			})
		}



		const subjectRef = ref();
		const subjectForm = reactive({
			title:'',
			subject_cate:'',
			thumb: '',
			package_id: '',
			desc:'',
			year:'',
		})
		const subject_rules = reactive({
			title: [
				{ required: true, message: proxy.$t('apply_work.tip35'), trigger: 'blur' },
				
			],
			subject_cate: [
				{ required: true, message: proxy.$t('apply_work.tip37'), trigger: 'blur' },
				
			],
			thumb: [
				{ required: true, message: proxy.$t('apply_work.tip42'), trigger: 'blur' },
				
			],	
			package_id: [
				{ required: true, message: proxy.$t('apply_work.select_price_pack'), trigger: 'blur' },
				
			],
			year: [
				{ required: true, message: proxy.$t('apply_work.yearTip'), trigger: 'blur' },
				
			],
		})

		const submitSubjectForm = (formEl) => {
			bus.emit('needRefresh', true)
			if (!formEl) return
			data.disabled = true;
			formEl.validate((valid, fields) => {
			    if (valid) {
				    console.log(valid,'submit!')
				   	if(!subjectForm.thumb){
				   		ElMessage({
						    message: proxy.$t('apply_work.tip42'),
						    grouping: true,
						    type: 'warning',
						})
						return;
				   	}else{
				   		store.dispatch("user/AddSubject", subjectForm).then((res)=>{
					      	console.log("AddSubject",res);
					      	ElMessage({
							    message: proxy.$t('apply_common.submit_success'),
							    grouping: true,
							    type: 'success',
							})
							UserSubjectList(data.subtype);
					      	data.showSubject = false;

					    }).catch(err => {
				            // console.error(err)
				            ElMessage({
							    message: err.message,
							    grouping: true,
							    type: 'error',
							})
							data.disabled = false;
				        })
				   	}
			    	
			    } else {
			      data.disabled = false;
			      console.log('error submit!')
			      return false
			    }
			})
		}
		const allCategorys = () => {			
			store.dispatch("common/getAllSubjectCats",{

	    	}).then((res)=>{		      	
		      	data.allCats = res;
		      	console.log("allCategorys",data.allCats);
		    }).catch(err => {
	            console.error(err)
	            
	        })
		}

		const handleClick = (tab, event) => {
		  console.log(tab.paneName, "tabs")
		  
		  if(tab.paneName == 'first'){
		  	data.subtype = 1;
		  }else if(tab.paneName == 'second'){
		  	data.subtype = 2;
		  	// console.log(data.hasVideoPermissions,"data.hasVideoPermissions");
		  }
		  UserSubjectList(data.subtype);
		}
			
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
   
			}
		}
		
		const UserSubjectList = (subtype=1,page=1,search='') => {
			store.dispatch("user/UserSubjectList",{
				subtype: subtype,
				need_uid: 1,
				page: page,
				search: search,
			}).then((res)=>{
				console.log(res,"subject");
				if(res.data.length>0){
					// data.subjectList = res.data;
					if(page == 1){
	        			data.subjectList = res.data.slice();
	        		}else{
	        			data.subjectList = data.subjectList.concat(res.data.slice());
	        		}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}
				if(!data.nomore){
	        		data.page = page+1;
	        	}
				
			}).catch(err=>{

			})
		}
		const getSubjectData = (event) => {
			console.log(event,"event-1");
			for(let i=0;i<data.subjectList.length;i++){
				if(event==data.subjectList[i].id){
					picOneForm.keywords = data.subjectList[i].keywords;
					picOneForm.description = data.subjectList[i].desc;
					picOneForm.package_id = data.subjectList[i].package_id;
					picOneForm.year = data.subjectList[i].year;
					break;
				}
			}
		}

		const ApplyInfo = () => {
			store.dispatch("user/ApplyInfo",{

			}).then((res) => {
				console.log(res,"ApplyInfo");
				if(Object.keys(res).length > 0){
					if(res.status==0){// 审核中
						router.push({name:'apply_passing'});
					}else if(res.status==2){// 审核未通过
						router.push({name:'apply_nopass'});
					}else if(res.status==1){// 审核未通过
						GetApplyContract().then(contract=>{
							if(Object.keys(res).length > 0){
								if(contract.status == 1){
									let worktype = res.worktype;
									if(worktype.indexOf('image')===-1){
										data.hasImagePermissions = false;
									}else{
										data.hasImagePermissions = true;
									}
									if(worktype.indexOf('video')===-1){
										data.hasVideoPermissions = false;
									}else{
										data.hasVideoPermissions = true;
									}
								}else{
									router.push({name:'apply_pass'})
								}
							}else{
								router.push({name:'apply_contract'})
							}
							
						});
						
					}
					
					
				}else{
					router.push({name:'no_settled'});
				}
				bus.emit('showFooter',true);
			})
		}

		// 获取合同信息
		const GetApplyContract = () => {
			return new Promise((resolve, reject) => {
		        store.dispatch("user/GetApplyContract",{}).then(res => {
		        	console.log(res,"GetApplyContract");
		        	resolve(res);
		        }).catch(err=>{
		        	reject(err);
		        })
		    })
		}

		const toApplication = () => {
			router.push({name:'application'})
		}
		const getImagePackages = () => {
			store.dispatch('user/ImagePackages').then(res=>{
				// console.log(res.image,'ImagePackages');
				data.ImagePackages = res.image;
				data.VideoPackages = res.video;
			})
		}
		const del = (type, index) => {
			if(type=='image'){
				// data.images.splice(index,1);
				// ruleUser.images.splice(index,1);
			}else if(type=='video'){
				// data.video.splice(index,1);
				// ruleUser.video.splice(index,1);
			}
		}

		const loadPicInfo=()=>{
			if(id>0 && type==1){
				data.showImgUpload = false;
				data.showPicForm = true;
				store.dispatch('user/CameraWorkDetail',{id:id}).then(res=>{
					// console.log(res,'CameraWorkDetail');
					ruleForm.tableData[0].title = res.title;
		    		ruleForm.tableData[0].description = res.description;
		    		ruleForm.tableData[0].keywords = res.keywords;//res.keywords.join(',');
		    		ruleForm.tableData[0].subject_id = res.subject_id;
		    		ruleForm.tableData[0].package_id = res.package_id;
		    		ruleForm.tableData[0].thumb = res.thumb;
		    		ruleForm.tableData[0].year = res.year;
				})
				
			}else if(id>0 && type==2){
				data.showVideoUpload = false;
				data.showVideoForm = true;
				store.dispatch('user/CameraWorkDetail',{id:id}).then(res=>{
					// console.log(res,'CameraWorkDetail2');
					ruleVideoForm.tableData[0].title = res.title;
		    		ruleVideoForm.tableData[0].description = res.description;
		    		ruleVideoForm.tableData[0].keywords = res.keywords;//res.keywords.join(',');
		    		ruleVideoForm.tableData[0].thumb = res.thumb;
		    		ruleVideoForm.tableData[0].url = res.url;
				})
			}
		}
		// const loadPicInfo=()=>{
		// 	if(id && type==1){
		// 		data.showImgUpload = false;
		// 		data.showPicForm = true;
		// 		store.dispatch('user/CameraWorkDetail',{id:id}).then(res=>{
		// 			console.log(res,'CameraWorkDetail');
		// 		})
		// 	} else if(type==2){
		// 		data.showVideoUpload = false;
		// 		data.showVideoForm = true;
		// 	}
		// }



        onMounted(async()=>{
        	loadPicInfo();
        	ApplyInfo();
        	// console.log(imginfo,"imginfo");
        	UserSubjectList(data.subtype,1);
        	allCategorys();
        	getImagePackages();
        	await nextTick()
        	setTimeout(() => {
		      data.isLoading = false;
		      viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			handleClick,
			viewBox,
			scroll,
			ruleRef,
			ruleForm,
			_rules,			
			UserSubjectList,
			submitForm,
			allCategorys,
			subjectForm,
			subjectRef,
			subject_rules,
			submitSubjectForm,
			// hancleMinChange,
			// hancleMaxChange,
			// hancleOriginChange,
			ruleVideoRef,
			ruleVideoForm,
			video_rules,
			submitVideoForm,
			ApplyInfo,
			GetApplyContract,
			toApplication,
			labelPosition,
			getImagePackages,
			picOneRef,
			picOneForm,
			videoOneRef,
			videoOneForm,
			del,
			loadPicInfo,
			getSubjectData,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch:{

	},
	methods: {
		init() {
			if(document.documentElement.clientWidth >= 768){
				this.isMobile = false;
			}else{
				this.isMobile = true;
			}
			window.addEventListener('resize', function(event){
				if(document.documentElement.clientWidth >= 768){
					this.isMobile = false;
				}else{
					this.isMobile = true;
				}
			});
		},
		handleKeyword(type,index=false) {
			if(type=='image'){
				if(index!==false){
					let newKeywords = this.ruleForm.tableData[index].keywords.slice();
					this.ruleForm.tableData[index].pic_keyword && newKeywords.push(this.ruleForm.tableData[index].pic_keyword);
					this.ruleForm.tableData[index].keywords = newKeywords;
					this.ruleForm.tableData[index].pic_keyword = '';
				}else{
					this.pic_keyword && this.picOneForm.keywords.push(this.pic_keyword);
					this.pic_keyword = '';
				}
				
			}else if(type=='video'){
				if(index!==false){
					let newKeywords = this.ruleVideoForm.tableData[index].keywords.slice();
					this.ruleVideoForm.tableData[index].video_keyword && newKeywords.push(this.ruleVideoForm.tableData[index].video_keyword);
					this.ruleVideoForm.tableData[index].keywords = newKeywords;
					this.ruleVideoForm.tableData[index].video_keyword = '';
				}else{
					this.video_keyword && this.videoOneForm.keywords.push(this.video_keyword);
					this.video_keyword = '';
				}
			}
		},
		
		delTag(tagIndex,type,index=false) {
			if(type=='image'){
				
				if(index===false){
					this.picOneForm.keywords.splice(tagIndex,1);
				}else{
					let aa = this.ruleForm.tableData[index].keywords.slice();
					aa.splice(tagIndex,1);
					this.ruleForm.tableData[index].keywords = aa;
				}
					
			}else if(type=='video'){
				if(index===false){
					console.log(index,"index1");
					this.videoOneForm.keywords.splice(tagIndex,1);
				}else{
					let aa = this.ruleVideoForm.tableData[index].keywords.slice();
					aa.splice(tagIndex,1);
					this.ruleVideoForm.tableData[index].keywords = aa;
				}
			}
		},
		delItem(index){
			if(this.subtype==1){
        		this.ruleForm.tableData.splice(index,1);
        	}else if(this.subtype==2){           		
        		this.ruleVideoForm.tableData.splice(index,1);
        	}
		},
		addItem(){
			
			if(this.subtype==1){
				let obj = {
					title:'',			
		  			description:this.picOneForm.description,
		  			keywords: this.picOneForm.keywords,	
		  			subject_id:this.picOneForm.subject_id,
		  			package_id:this.picOneForm.package_id,
		  			year:this.picOneForm.year,
				};
        		this.ruleForm.tableData.push(obj);
        		console.log(this.ruleForm.tableData,"tabledata");
        	}else if(this.subtype==2){    
        	    let videoObj = {
					title:'',			
		  			description: this.videoOneForm.description,
		  			keywords: this.videoOneForm.keywords,
		  			
				};     		
        		this.ruleVideoForm.tableData.push(videoObj);
        	}
		},
		addTopic() {
			this.showSubject = true;
		},
		close() {
			this.showSubject = false;
		},
		searchUserSubjects() {
			this.UserSubjectList(this.subtype, this.page,this.search);
		},
		loadMore() {
			this.UserSubjectList(this.subtype, this.page);
		},
		handleCameraDrop(e) {
		    e.preventDefault();
		    // let files = e.dataTransfer.files;
		    this.picFile = e.dataTransfer.files;
		    console.log(this.picFiles,"this.picFiles");
      //       this.$store.dispatch('common/uploadPic', {
      //       	uploadPic: this.picFile,
      //       	from: 'other',
      //       	type:'file',
      //       });
      //       this.$router.push({ name:'search_pic',params:{ where:'other' } });
	    },
	    handleCameraDragOver(e) {
	      e.preventDefault();
	    },
	    uploadThumb(event){
	    	let that = this;
        	event.preventDefault();
            let file = event.currentTarget.files[0];
            // console.log(file,"file");
            var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
            	// console.log("upload-1",res);
            	// fileInfo.url = res.url;
            	that.subjectForm.thumb = res.url;
            }).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
	    },
	    uploadSingleFile(event,index,type){
	    	let that = this;
	    	// console.log(index,"index-1");
	    	// console.log(type,"type");
        	event.preventDefault();
        	if(type=='image'){
        		that.isImgSingleLoading = true;
        	}else if(type=='video'){        		
        		that.isVideoSingleLoading = true;
        	}
        	
            let file = event.currentTarget.files[0];
            // console.log(file,"file");
            
            var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/checkSimilarImage', formData).then((res)=>{	            		
            	// console.log("upload-1",res);
            	// fileInfo.url = res.url;
            	if(type=='image'){
            		// console.log(that.ruleForm.tableData,"tableData");
            		// console.log(that.ruleForm.tableData[index],"tableData");
            		// console.log(index,"index");
            		that.ruleForm.tableData[index].title = file.name;
            		that.ruleForm.tableData[index].thumb = res.url;
            		if(res.similar.length > 0){
            			that.ruleForm.tableData[index].hasSimilar = true;
            		}else{
            			that.ruleForm.tableData[index].hasSimilar = false;
            		}
            		that.isImgSingleLoading = false;
            	}else if(type=='video'){
            		that.getVideoFirstFrame(res.url).then(thumb=>{
            			that.ruleVideoForm.tableData[index].title = file.name;
	            		that.ruleVideoForm.tableData[index].thumb = thumb.url;
	            		that.ruleVideoForm.tableData[index].url = res.url;
	            		that.isVideoSingleLoading = false;
	            	})
            		
            	}
            	
            }).catch(err=>{
            	if(type=='image'){
            		that.isImgSingleLoading = false;
            	}else if(type=='video'){
            		that.isVideoSingleLoading = false;
            	}
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
	    },
	  //   uploadSingleFile(event,index,type){
	  //   	let that = this;
	  //   	// console.log(index,"index-1");
	  //   	// console.log(type,"type");
   //      	event.preventDefault();
   //      	if(type=='image'){
   //      		that.isImgSingleLoading = true;
   //      	}else if(type=='video'){        		
   //      		that.isVideoSingleLoading = true;
   //      	}
        	
   //          let file = event.currentTarget.files[0];
   //          // console.log(file,"file");
            
   //          var formData = new FormData();
   //  		formData.append("file", file);
   //      	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
   //          	// console.log("upload-1",res);
   //          	// fileInfo.url = res.url;
   //          	if(type=='image'){
   //          		// console.log(that.ruleForm.tableData,"tableData");
   //          		// console.log(that.ruleForm.tableData[index],"tableData");
   //          		// console.log(index,"index");
   //          		that.ruleForm.tableData[index].title = file.name;
   //          		that.ruleForm.tableData[index].thumb = res.url;
   //          		that.isImgSingleLoading = false;
   //          	}else if(type=='video'){
   //          		that.getVideoFirstFrame(res.url).then(thumb=>{
   //          			that.ruleVideoForm.tableData[index].title = file.name;
	  //           		that.ruleVideoForm.tableData[index].thumb = thumb.url;
	  //           		that.ruleVideoForm.tableData[index].url = res.url;
	  //           		that.isVideoSingleLoading = false;
	  //           	})
            		
   //          	}
            	
   //          }).catch(err=>{
   //          	if(type=='image'){
   //          		that.isImgSingleLoading = false;
   //          	}else if(type=='video'){
   //          		that.isVideoSingleLoading = false;
   //          	}
			// 	ElMessage({
			//         message: err.message,
			//         type: "warning",
			//     });
			// })
	  //   },
	    uploadCameraFile(event, type, again=false){
        	let that = this;
        	event.preventDefault();
        	console.log(event,"event");
        	if(type=='image'){
        		that.isPicLoading = true;
        	}else{        		
        		that.isVideoLoading = true;
        	}
            this.picFiles = event.currentTarget.files;
            console.log(this.picFiles,"this.picFiles");
            let has = false;

            if(this.picFiles.length > 0){
            	if(this.picFiles.length > 8){
            		if(type=='image'){
            			that.isPicLoading = false;
	            		ElMessage({
					        message: this.$t('apply_common.upload_pic_eight'),
					        type: "warning",
					    });
	            	}else{
	            		that.isVideoLoading = false;
	            		ElMessage({
					        message: this.$t('apply_common.upload_video_eight'),
					        type: "warning",
					    });
	            	}
            		
            	}else{
            		if(!again){
            			if(type=='image'){
		            		that.ruleForm.tableData.splice(0,1);
		            	}else{
		            		that.ruleVideoForm.tableData.splice(0,1);
		            	}
            		}		            	
	            	
	            	for(let i=0;i<this.picFiles.length;i++){
	            		let fileInfo = {};
	            		fileInfo.title = this.picFiles[i].name;	            		
	            		// this.ruleForm.tableData[i].title = fileInfo.name;
	            		if(type=='image'){
	            			fileInfo.description = this.picOneForm.description;
	            			fileInfo.keywords = this.picOneForm.keywords;
	            			fileInfo.subject_id = this.picOneForm.subject_id;
	            			fileInfo.package_id = this.picOneForm.package_id;
	            			fileInfo.year = this.picOneForm.year;
	            			// this.storageKeyPic = 'keywords_pic';
	            			localStorage.setItem(this.storageKeyPic, JSON.stringify(this.picOneForm));
		            	}else if(type=='video'){
		            		fileInfo.description = this.videoOneForm.description;
	            			fileInfo.keywords = this.videoOneForm.keywords;
	            			// this.storageKeyVideo = 'keywords_video';
	            			localStorage.setItem(this.storageKeyVideo, JSON.stringify(this.videoOneForm));
		            	}
	            		// console.log(this.picFiles[0],"this.picFiles-1");
	            		var formData = new FormData();
	            		formData.append("file", this.picFiles[i]);
		            	this.$store.dispatch('user/checkSimilarImage', formData).then((res)=>{	            		
			            	// console.log("upload",res);
			            	// fileInfo.url = res.url;
			            	
			            	if(type=='image'){
			            		fileInfo.thumb = res.url;
			            		if(res.similar.length > 0){
			            			fileInfo.hasSimilar = true;
			            		}else{
			            			fileInfo.hasSimilar = false;
			            		}
			            		
			            		that.isPicLoading = false;
			            		that.ruleForm.tableData.push(fileInfo);
			            		// console.log(that.ruleForm.tableData,"filesInfo")
			            		if(!again){
				            		that.showImgUpload = false;
				            		that.showPicForm = true;
				            	}
			            	}else if(type=='video'){
			            		that.getVideoFirstFrame(res.url).then(thumb=>{
				            		fileInfo.thumb = thumb.url;
				            		fileInfo.url = res.url;
				            		that.isVideoLoading = false;
				            		that.ruleVideoForm.tableData.push(fileInfo);
				            		// console.log(that.ruleVideoForm.tableData,"videoInfo")
				            		if(!again){
					            		that.showVideoUpload = false;
					            		that.showVideoForm = true;
					            	}
				            	})
			            	}

			            }).catch(err=>{
			            	if(type=='image'){
			            		that.isPicLoading = false;
			            	}else if(type=='video'){
			            		that.isVideoLoading = false;
			            	}
							ElMessage({
						        message: err.message,
						        type: "warning",
						    });
						})
		            }
		        }
	            
            }

        },
      //   uploadCameraFile(event, type, again=false){
      //   	let that = this;
      //   	event.preventDefault();
      //   	console.log(event,"event");
      //   	if(type=='image'){
      //   		that.isPicLoading = true;
      //   	}else{        		
      //   		that.isVideoLoading = true;
      //   	}
      //       this.picFiles = event.currentTarget.files;
      //       console.log(this.picFiles,"this.picFiles");
      //       let has = false;

      //       if(this.picFiles.length > 0){
      //       	if(this.picFiles.length > 8){
      //       		if(type=='image'){
      //       			that.isPicLoading = false;
	     //        		ElMessage({
					 //        message: this.$t('apply_common.upload_pic_eight'),
					 //        type: "warning",
					 //    });
	     //        	}else{
	     //        		that.isVideoLoading = false;
	     //        		ElMessage({
					 //        message: this.$t('apply_common.upload_video_eight'),
					 //        type: "warning",
					 //    });
	     //        	}
            		
      //       	}else{
      //       		if(!again){
      //       			if(type=='image'){
		    //         		that.ruleForm.tableData.splice(0,1);
		    //         	}else{
		    //         		that.ruleVideoForm.tableData.splice(0,1);
		    //         	}
      //       		}		            	
	            	
	     //        	for(let i=0;i<this.picFiles.length;i++){
	     //        		let fileInfo = {};
	     //        		fileInfo.title = this.picFiles[i].name;
	            		
	            		
	     //        		// this.ruleForm.tableData[i].title = fileInfo.name;
	     //        		if(type=='image'){
	     //        			fileInfo.description = this.picOneForm.description;
	     //        			fileInfo.keywords = this.picOneForm.keywords;
	     //        			fileInfo.subject_id = this.picOneForm.subject_id;
	     //        			fileInfo.package_id = this.picOneForm.package_id;
	     //        			fileInfo.year = this.picOneForm.year;
	     //        			// this.storageKeyPic = 'keywords_pic';
	     //        			localStorage.setItem(this.storageKeyPic, JSON.stringify(this.picOneForm));
		    //         	}else if(type=='video'){
		    //         		fileInfo.description = this.videoOneForm.description;
	     //        			fileInfo.keywords = this.videoOneForm.keywords;
	     //        			// this.storageKeyVideo = 'keywords_video';
	     //        			localStorage.setItem(this.storageKeyVideo, JSON.stringify(this.videoOneForm));
		    //         	}
	     //        		// console.log(this.picFiles[0],"this.picFiles-1");
	     //        		var formData = new FormData();
	     //        		formData.append("file", this.picFiles[i]);
		    //         	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
			   //          	// console.log("upload",res);
			   //          	// fileInfo.url = res.url;
			            	
			   //          	if(type=='image'){
			   //          		fileInfo.thumb = res.url;
			   //          		that.isPicLoading = false;
			   //          		that.ruleForm.tableData.push(fileInfo);
			   //          		// console.log(that.ruleForm.tableData,"filesInfo")
			   //          		if(!again){
				  //           		that.showImgUpload = false;
				  //           		that.showPicForm = true;
				  //           	}
			   //          	}else if(type=='video'){
			   //          		that.getVideoFirstFrame(res.url).then(thumb=>{
				  //           		fileInfo.thumb = thumb.url;
				  //           		fileInfo.url = res.url;
				  //           		that.isVideoLoading = false;
				  //           		that.ruleVideoForm.tableData.push(fileInfo);
				  //           		// console.log(that.ruleVideoForm.tableData,"videoInfo")
				  //           		if(!again){
					 //            		that.showVideoUpload = false;
					 //            		that.showVideoForm = true;
					 //            	}
				  //           	})
			   //          	}

			   //          }).catch(err=>{
			   //          	if(type=='image'){
			   //          		that.isPicLoading = false;
			   //          	}else if(type=='video'){
			   //          		that.isVideoLoading = false;
			   //          	}
						// 	ElMessage({
						//         message: err.message,
						//         type: "warning",
						//     });
						// })
		    //         }
		    //     }
	            
      //       }

      //   },
        getVideoFirstFrame(url, file='') {
	    	return new Promise((resolve, reject) => {
				const video = document.createElement('video') // 创建video对象
				video.src = url // url地址
				const canvas = document.createElement('canvas') // 创建 canvas 对象
				const ctx = canvas.getContext('2d') // 绘制2d
				video.crossOrigin = 'anonymous' // 解决跨域问题，也就是提示污染资源无法转换视频
				video.currentTime = 1 // 第一秒帧
				// console.log(video,"video")
				video.oncanplay = (e) => {
					// console.log('wh',e.target.videoWidth,e.target.videoHeight);
    				
				    canvas.width = e.target.videoWidth
				    canvas.height = e.target.videoHeight
				    // 利用canvas对象方法绘图
				    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

				    // 转换成base64形式
				    let base64_file = canvas.toDataURL('image/png');
				    // console.log(base64_file,"base64_file");
				    this.$store.dispatch('user/UploadBase64File',{base64_file: base64_file}).then(res=>{
				    	resolve({
				    		base64_file: base64_file,
				    		url: res.url,
				    	});
				    });
				    
				    // Vue.set(file, 'imageShow', canvas.toDataURL('image/png')) // 获取视频第一帧作为imageShow展示
				}
			})
		},
	}
})