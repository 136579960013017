import { nextTick,defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import { createStore } from 'vuex';
import { 
  PicCats, 
  PicLabels, 
  SiteInfo, 
  PhotoCats,
  PicLists,
  PicVideoDetail,
  SimilarPicDetail,
  vrList,
  postPic,
  NewsList,
  NewsDetail,
  SubjectCats,
  CreateOrder,
  OrderInfo,
  AllSubjectCats,
  SubjectList,
  SubjectPic,
  ArList,
  ArDetail,
  ArNewsDetail,
  PackList,
  PackPrevileges,
  GetIndexNews,
  GetIndexSlider,
  GetSubjectDetail,
  GetSubjectList,
  TaleList,
  TaleDetail,
} from '@/api/common';

// import {PHOTO_CATS} from '@/store/mutation-types';

import { reorderArray,getImageSize,getImageInfo } from "@/utils/utils";
import { useCookies } from "vue3-cookies";
import i18n from "@/lang";

const t = i18n.global.t;


const { cookies } = useCookies();

// 设置cookie
// cookies.set('k2', 'v2', '1h')
// console.log(cookies.get('k2'),"k2");
const state = {
    infos:"公共数据",
  	token:'2f68dbbf-519d-4f01-9636-e2421668f379',
  	count: 0,
    siteInfo:{},
    uploadPic:{},
    postPics:{},
    from: '', // 是否来自其它页面 other标表示来自其他页面
    newsList:{},
    newsDetail:{},
    keywords:{},
    type:'default',
    createOrderParam:{},
    orderInfo:{},
    subjectList:[],
    metaInfo:{},
}
// 相当于事件
const mutations = {
  saveSiteInfo(state, info) {
    state.siteInfo = info;
  },
  CHANGE_META_INFO(state, metainfo) {
    state.metaInfo = metainfo;
  },
	increment (state) {
    // 这里的 `state` 对象是模块的局部状态
    state.count++;
  },
  saveUploadPic(state, param={}) {
    state.uploadPic = param.uploadPic;
    state.from = param.from;
    state.type = param.type;
  },
  postPics(state, data={}){
    state.postPics = data;
  },
  saveNewsList(state, info) {
    state.newsList = info;
  },
  saveNewsDetail(state, info={}) {
    state.newsDetail = info;
  },
  saveKeywords(state, info={}){
    state.keywords = info;
    state.from = info.from;
    state.type = info.type;
  },
  saveOrderParam(state, param={}){
    state.createOrderParam = param;
  },
  saveOrderInfo(state, info={}) {
    state.orderInfo = info
  },
  saveSubjectList(state, data=[]) {
    state.subjectList = data
  },
}

const actions = {
    uploadPic(context, param={}){
      context.commit("saveUploadPic", param);
    },
    searchKeywords(context, param={}){
      context.commit("saveKeywords", param);
    },
    saveOrderParam(context, param={}){
      console.log(param,"param");
      if(param.imgList && param.imgList.length>0){
        param.imgList = param.imgList.map((item)=>{
          if(item.option_all==undefined){
            item.option_all = [];
            item.option.forEach((val,index)=>{
              let obj = {
                id: index,
                price: parseInt(val.price),
                width: val.width,
                height: val.height,
                isChecked: false,
              };
              console.log(param.images,"images");
              for(let i=0;i<param.images.length;i++){
                if(param.images[i].image_id == item.id && param.images[i].option.indexOf(index)!==-1){
                  obj.isChecked = true;

                }
              }

              item.option_all.push(obj);
            })
            
          }else{

          }
          return item;
        });
      }
      
      context.commit("saveOrderParam", param);
    },
    TaleList(context, param={}) {
      return new Promise((resolve, reject) => {
        TaleList(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    TaleDetail(context, param={}) {
      return new Promise((resolve, reject) => {
        TaleDetail(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    GetSubjectList(context, param={}) {
      return new Promise((resolve, reject) => {
        GetSubjectList(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    GetSubjectDetail(context, param={}) {
      return new Promise((resolve, reject) => {
        GetSubjectDetail(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    GetIndexSlider(context, param={}) {
      return new Promise((resolve, reject) => {
        GetIndexSlider(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    GetIndexNews(context, param={}) {
      return new Promise((resolve, reject) => {
        GetIndexNews(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    PackList(context, param={}) {
      return new Promise((resolve, reject) => {
        PackList(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    PackPrevileges(context, param={}) {
      return new Promise((resolve, reject) => {
        PackPrevileges(param).then(async(data)=>{ 
          let arr = []; 
          const groups = data.reduce((arr, product) => {
            const { type } = product;
            arr[type] = arr[type] ?? [];
            arr[type].push(product);

            return arr;
          }, {});

          let keys = Object.keys(groups);
          // console.log(keys,"keys");

          let array = [];
          for(let i=0;i<keys.length;i++){
            let typename = '';
            if(keys[i] == 1){
              typename = t('package.tip4');
            }else if(keys[i] == 2){
              typename = t('package.tip5');
            }else if(keys[i] == 3){
              typename = t('package.tip6');
            }
            array[i] = {};
            array[i].name = typename;
            array[i].children = groups[keys[i]].reduce((arr2,item)=>{
              // console.log(arr2,"arr2");
              const { group } = item;
              // arr2 = [];
              // arr2[] = {};
              arr2[group] = arr2[group] ?? [];
              arr2[group].push(item);
              return arr2;
            },{})
          }
          console.log(array,"array");
          // console.log(groups,"groups");
          resolve(array);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    ArList(context, param={}) {
      return new Promise((resolve, reject) => {
        ArList(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    ArDetail(context, param={}) {
      return new Promise((resolve, reject) => {
        ArDetail(param).then((data)=>{ 
          if(document.documentElement.clientWidth < 768){
          let p = document.getElementsByTagName("p");
        console.log(p,"p");
        for (var i = 0; i<p.length; i++) {              
              if(p[i].childNodes.length > 0 && p[i].childNodes[0] == 'img'){
            p[i].style.textIndent = '0rem';
          } 
          }
        let tags = document.getElementsByTagName("img");
        console.log(tags,"tags");
        for (var i = 0; i<tags.length; i++) {             
              tags[i].style.width = '100%'; 
              tags[i].style.height = 'auto';    
              tags[i].width = '100%'; 
              tags[i].height = 'auto';
          }
        
      }else{
        let p = document.getElementsByTagName("p");
        console.log(p.length,"p length");
        for (var i = 0; i<p.length; i++) {  
          console.log(p[i].childNodes,"childNodes");
          if(p[i].childNodes.length > 0 && p[i].childNodes[0] == 'img'){
            p[i].style.textIndent = '0rem';
            continue;
          }        
          
              // p[i].style.maxWidth = '800px'; 
              // p[i].style.height = 'auto';    
              // p[i].width = '100%'; 
              // p[i].height = 'auto';
          }
          let tags = document.getElementsByTagName("img");
        console.log(tags,"tags");
        for (var i = 0; i<tags.length; i++) {             
              tags[i].style.maxWidth = '800px'; 
              
          }
      }  
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    ArNewsDetail(context, param={}) {
      return new Promise((resolve, reject) => {
        ArNewsDetail(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    fetchSiteInfo(context) {
        return new Promise((resolve, reject) => {
          SiteInfo().then((data)=>{
            // console.log(data,'site~~info')
            // console.log(data.hot,"hot");
            let hot1 = [], hot2 = [],hots=[];
            // hots = reorderArray(data.hot);
            data.hot1 = []
            data.hot2 = []
            hots = data.hot;
           
            let verticalPics = [];
            if(hots.length > 0){
              for(let i = 0;i < hots.length; i++){
                if(hots[i].direction == 3){
                  verticalPics.push(hots[i]);
                  hots.splice(i,1);
                }
              }
            }
            
            // console.log(verticalPics,"verticalPics")
            if(verticalPics.length>0){
              for(let i=0;i<verticalPics.length;i++){
                if(i==0 || i==1){
                  hots.splice(i+1,0,verticalPics[i]);
                }
              }
              // hots.splice(1,0,verticalPics[0]);
              // hots.splice(2,0,verticalPics[1]);
              
            }
            // console.log(hots,"hots");
            if(hots.length > 0){
              hots.map((item,index)=>{
                item.first = false;
                if(index == 0 || index == 2){
                  item.first = true;
                }
                if(index < 2){
                  data.hot1.push(item);
                }else if(index >= 2){
                  data.hot2.push(item);
                }
              })
            }
            
            if(data.hot2.length>0){
              for(let i=0;i<data.hot2.length;i++){
                if(i>0&&data.hot2[i].direction == 3){
                  data.hot2.splice(i,1);
                }
              }
            }
            
            context.commit("saveSiteInfo", data);
                      
            resolve(data)
          }).catch(err => {
            reject(err)
            console.error(err)
          })

        })
    },
    postPic(context,param={}){
      return new Promise((resolve, reject) => {
        postPic(param).then((data)=>{   

          context.commit("postPics", data);

          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getPicCats(context) {
      return new Promise((resolve, reject) => {
        PicCats().then((data)=>{        
          data.map((item)=>{
            item.url = '#/topic_pic_category/'+item.id;
            return item;
          });
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getSubjectCats(context,param={}){
      return new Promise((resolve, reject) => {
        SubjectCats(param).then((data)=>{        
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getAllSubjectCats(context,param={}){
      return new Promise((resolve, reject) => {
        AllSubjectCats(param).then((data)=>{        
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getSubjectList(context,param={}){
      return new Promise((resolve, reject) => {
        SubjectList(param).then((data)=>{  
          console.log(data,'subject data');
          let arr = [];
          let obj = {};
          data.data.forEach((item,index)=>{
            // (async () => {
            //   let size = await getImageSize(item.thumb);
            //   // console.log(size,"size");
            //   item.ratio_w = size.width;
            //   item.ratio_h = size.height;
            //   arr.push(item);
            //   if(arr.length==data.data.length){
            //     obj.data = arr;
            //     obj.pagination = data.pagination
            //     context.commit("saveSubjectList",obj);
            //     resolve(obj);
            //   }
            // })();
            getImageSize(item.thumb).then(size=>{
              item.ratio_w = size.width;
              item.ratio_h = size.height;
              arr.push(item);
              if(arr.length==data.data.length){
                obj.data = arr;
                obj.pagination = data.pagination
                context.commit("saveSubjectList",obj);
                resolve(obj);
              }
            })
          })
             
          // resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getSubjectPic(context,param={}){
      // console.log(param,"Subject pic param");
      return new Promise((resolve, reject) => {
        SubjectPic(param).then((data)=>{        
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getPicVideoDetail(context, param={}){
      return new Promise((resolve, reject) => {
        PicVideoDetail(param).then((data)=>{
          console.log(data,'PicVideoDetail');
          if(data.type==1 && !data.ratio_w && !data.ratio_h){
            (async () => {
              let obj = {};
              let info = await getImageInfo(data.watermark);
              // console.log(info,"info");
              const fileImg = await fetch(data.watermark).then(r => r.blob());
              // console.log(fileImg, 'fileImg')
              let strarr = data.watermark.split('.');
              data.ratio_w = info.width;
              data.ratio_h = info.height;
              data.size = fileImg.size;
              data.extension = strarr[strarr.length-1];
              resolve(data);
              
            })();
          }else{
            resolve(data);
          }
          
          // resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getSimilarPicDetail(context, param={}){
      return new Promise((resolve, reject) => {
        SimilarPicDetail(param).then((data)=>{
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getPicLists(context, param={}) {
      return new Promise((resolve, reject) => {
        PicLists(param).then((data)=>{ 
        console.log(data,"videos")      
          // data.data.map((item)=>{
          //   if(item.type==2){
          //     item.link = '#/topic_video_detail/'+item.id;
          //   }else{
          //     item.url = '#/topic_pic_detail/'+item.id;
          //   }
            
          //   return item;
          // });
          let arr = [];
          let obj = {};
          // data.data.forEach(async(item,index)=>{
          //   // (async () => {
          //     let img = item.preview;
          //     if(item.type==2){
          //       img = item.thumb;
          //     }
          //     let size = await getImageSize(img);
          //     // console.log(size,"size");
          //     item.ratio_w = size.width;
          //     item.ratio_h = size.height;
          //     arr.push(item);
          //     if(arr.length==data.data.length){
          //       obj.data = arr;
          //       obj.pagination = data.pagination
                
          //       resolve(obj);
          //     }
          //   // })();
          // })
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    getPicLabels(context) {
      return new Promise((resolve, reject) => {
          PicLabels().then((data)=>{        
            data.map((item)=>{
              item.url = '#/topic_pic_detail/'+item.id;
              item.selectedOption = [];
              return item;
            });
            resolve(data);
          }).catch((err)=>{
            reject(err)
            console.error(err)
          });
        })
    },
    getPhotoCats(context) {
        return new Promise((resolve, reject) => {
          PhotoCats().then((data)=>{        
            data.map((item)=>{
              item.url = '#/photo_grapher/'+item.id;
              item.selectedOption = [];
              return item;
            });
            resolve(data);
          }).catch((err)=>{
            reject(err)
            console.error(err)
          });
        })
      // }
      
    },
    getVRList(context, param={}) {
      return new Promise((resolve, reject) => {
        vrList(param).then((data)=>{       
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    /**
     * 视讯列表
     */
    getNewsList(context, param={}) {
      return new Promise((resolve, reject) => {
        NewsList(param).then((data)=>{   
          context.commit("saveNewsList", data);    
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    /**
     * 视讯详情
     */
    getNewsDetail(context, param={}) {
      return new Promise((resolve, reject) => {
        NewsDetail(param).then((data)=>{  
          let arr = [];
          let obj = {};
          // data.image.forEach((item,index)=>{
          //   (async () => {
          //     let size = await getImageSize(item.thumb);
          //     // console.log(size,"size");
          //     item.ratio_w = size.width;
          //     item.ratio_h = size.height;
          //     arr.push(item);
          //     if(arr.length==data.image.length){
          //       obj = data;
          //       obj.image = arr;
                
          //       // context.commit("saveSubjectList",obj);
          //       resolve(obj);
          //     }
          //   })();
            
              
          // })
          // context.commit("saveNewsDetail", data);     
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },  
    CreateOrder(context, param={}){
      return new Promise((resolve, reject) => {
        CreateOrder(param).then((data)=>{  
          context.commit("saveOrderInfo", data);
          resolve(data);
        }).catch((err)=>{
          ElMessage({
              message: err,
              type: "warning",
          }); 
          reject(err)          
          console.error(err)
        });
      })
    },
    getOrderInfo(context, param={}){
      return new Promise((resolve, reject) => {
        OrderInfo(param).then((data)=>{  
          context.commit("saveOrderInfo", data);   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
    },
    

}

const getters = { 
	  getSiteInfo(state){
      return state.siteInfo;
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

/**
 * 创建仓库和导出
 */
// export default createStore({
//     namespaced: true,
//     state: {
//         infos:"公共数据",
//         token:'2f68dbbf-519d-4f01-9636-e2421668f379',
//         count: 0,
//         siteInfo:{},
//     },
//     //更改 Vuex 的 store 中的状态的唯一方法是提交 mutation。
//     mutations: {
//         add(state, payload) {
//             state.counter += payload
//         },
//         saveSiteInfo(state, info) {
//           state.siteInfo = info;
//         }
//     },
//     // actions 里面也是放函数的
//     actions: {
//         // addActions({ commit, dispatch, state, rootGetters, rootState, getters }, payload) { // 这个式结构的写法
//         addActions(context, payload) {
//             // 查看 context 的属性有哪些
//             console.log(context);

//             context.commit("add", payload)
//         },
//         // { commit, dispatch, state, rootGetters, rootState, getters } = context;
//         fetchSiteInfo(context) {
//             return new Promise((resolve, reject) => {
//               SiteInfo().then((data)=>{
//                 console.log(data, "siteinfo");
//                 context.commit("saveSiteInfo", data); 
//                 resolve(data)
//               }).catch(err => {
//                   reject(err)
//                   console.error(err)
//               })

//             })
//         },
//         getPicCats(context) {
//           PicCats().then((data)=>{        
//             data.map((item)=>{
//               item.url = '#/topic_pic_category/'+item.id;
//               return item;
//             });
//             resolve(data);
//           }).catch((err)=>{
//             reject(err)
//             console.error(err)
//           });
//         },
//         // fun2({ commit, dispatch, state, rootCommit, rootDispatch, rootState }) {
//         //     commit('someMutation', null, { root: true }) // 这个代表修改根目录下的 commit的某一个事件（someMutation），这个事件写在 mutations 里
//         //     dispatch('someOtherAction', null, { root: true }) // -> 个代表修改根目录下的 dispatch的某一个事件（someOtherAction），这个事件写在 actions 里
//         // },

//     },
//     getters: {
//       /**
//        * @param {*} state 当前的state
//        * @param {*} getters 当前的getters
//        * @param {*} rootState 根目录下的state
//        * @param {*} rootGetters 根目录下的getters
//        */
//       // fun1(state, getters, rootState, rootGetters) {

//       // }

//     },
// })
