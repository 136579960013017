import { createRouter, createWebHashHistory,createWebHistory } from 'vue-router';

import index from '@/views/index.vue';
import search_pic from '@/views/search_pic.vue';
import topic_pic from '@/views/topic_pic.vue';
import topic_pic_category from '@/views/topic_pic_category.vue';
import topic_pic_subcategory from '@/views/topic_pic_subcategory.vue';
import topic_video from '@/views/topic_video.vue';
import topic_video_detail from '@/views/topic/topic_video_detail.vue';
import topic_pic_detail from '@/views/topic/topic_pic_detail.vue';
import photo_grapher from '@/views/photo_grapher.vue';
import photo_grapher_album from '@/views/photo_grapher_album.vue';
import photo_grapher_detail from '@/views/photo_grapher_detail.vue';
import fvr from '@/views/vr/vr.vue';
import setting from '@/views/my/setting.vue';
import news from '@/views/news.vue';
import news_detail from '@/views/news_detail.vue';
import special_topics from '@/views/special_topics.vue';
import special_topics_category from '@/views/special_topics_category.vue';
// 订单
import order_list from '@/views/my/order/order_list.vue';
import order_pay from '@/views/my/order/order_pay.vue';
import order_detail from '@/views/my/order/order_detail.vue';
import pay_qrcode from '@/views/my/order/pay_qrcode.vue';
import cart from '@/views/my/cart.vue';
import wallet from '@/views/my/wallet.vue';
import auth_records from '@/views/my/auth_records.vue';

import favorites from '@/views/my/favorites.vue';
import home from '@/views/my/home.vue';
import content from '@/views/content.vue';
import article_list from '@/views/article.vue';
import article_detail from '@/views/article_detail.vue';
import price_package from '@/views/package.vue';
//生成订单合同
import contract from '@/views/my/contract.vue';

// 摄影师供稿
import no_settled from '@/views/my/grapher/no_settled.vue';
import application from '@/views/my/grapher/application.vue';
// 申请审核结果
import apply_pass from '@/views/my/grapher/apply_pass.vue';
import apply_nopass from '@/views/my/grapher/apply_nopass.vue';
import apply_passing from '@/views/my/grapher/apply_passing.vue';
// 供稿签约
import apply_contract from '@/views/my/grapher/apply_contract.vue';
import apply_info from '@/views/my/grapher/apply_info.vue';
import apply_work from '@/views/my/grapher/apply_work.vue';
import work_manage from '@/views/my/grapher/work_manage.vue';
import recharge from '@/views/my/grapher/recharge.vue';
// 图片视频征集
import active from '@/views/active.vue';
import active_detail from '@/views/active_detail.vue';
import active_list from '@/views/active_list.vue';
import active_collect_detail from '@/views/active_collect_detail.vue';
import active_collect_video_detail from '@/views/active_collect_video_detail.vue';

import subject_list from '@/views/subject_list.vue';
import subject_detail from '@/views/subject_detail.vue';

// 征集作品列表
import collect_work from '@/views/my/collect_work.vue';
// 合辑
import collect_folder from '@/views/my/collect_folder.vue';
import tale from '@/views/tale.vue';
import tale_detail from '@/views/tale_detail.vue';
import tale_video from '@/views/tale_video.vue';

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        meta:{
            title: '首页',
            requiresAuth: false,
            keepAlive: true,
            description:"",
            keywords: "",

        },
    },
    
    {
        path: '/subject_list',
        name: 'subject_list',
        component: subject_list,
        meta:{
            title: '专题列表',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/subject_detail',
        name: 'subject_detail',
        component: subject_detail,
        meta:{
            title: '专题详情',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/my/collect_folder',
        name: 'collect_folder',
        component: collect_folder,
        meta:{
            title: '合辑列表',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/collect_work',
        name: 'collect_work',
        component: collect_work,
        meta:{
            title: '活动征集作品列表',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/active_collect_detail',
        name: 'active_collect_detail',
        component: active_collect_detail,
        meta:{
            title: '活动征集作品图片详情',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/active_collect_video_detail',
        name: 'active_collect_video_detail',
        component: active_collect_video_detail,
        meta:{
            title: '活动征集作品视频详情',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/active_list',
        name: 'active_list',
        component: active_list,
        meta:{
            title: '活动列表',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/active_detail',
        name: 'active_detail',
        component: active_detail,
        meta:{
            title: '内容表单',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/active',
        name: 'active',
        component: active,
        meta:{
            title: '活动征集',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/tale',
        name: 'tale',
        component: tale,
        meta:{
            title: '青岛故事',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/tale_detail',
        name: 'tale_detail',
        component: tale_detail,
        meta:{
            title: '青岛故事详情',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/tale_video',
        name: 'tale_video',
        component: tale_video,
        meta:{
            title: '青岛故事视频',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/main',
        name: 'main',
        component: index,
        meta:{
            title: '主页',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/price_package',
        name: 'price_package',
        component: price_package,
        meta:{
            title: '价格套餐',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/content',
        name: 'content',
        component: content,
        meta:{
            title: '单页',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/article_list',
        name: 'article_list',
        component: article_list,
        meta:{
            title: '新闻列表',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/article_detail',
        name: 'article_detail',
        component: article_detail,
        meta:{
            title: '新闻详情',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/news',
        name: 'news',
        component: news,
        meta:{
            title: '视讯',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/news_detail',
        name: 'news_detail',
        component: news_detail,
        meta:{
            title: '视讯详情',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/search_pic',
        name: 'search_pic',
        component: search_pic,
        meta:{
            title: '以图搜图',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/topic_pic',
        name: 'topic_pic',
        component: topic_pic,
        meta:{
            title: '图片专题',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/special_topics',
        name: 'special_topics',
        component: special_topics,
        meta:{
            title: '图片专题',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/special_topics_category',
        name: 'special_topics_category',
        component: special_topics_category,
        meta:{
            title: '图片专题分类',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/topic_pic_category',
        name: 'topic_pic_category',
        component: topic_pic_category,
        meta:{
            title: '图片分类',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/topic_pic_subcategory',
        name: 'topic_pic_subcategory',
        component: topic_pic_subcategory,
        meta:{
            title: '图片细分类',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/topic_pic_detail',
        name: 'topic_pic_detail',
        component: topic_pic_detail,
        meta:{
            title: '图片基本信息',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/topic_video',
        name: 'topic_video',
        component: topic_video,
        meta:{
            title: '视频专题',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/topic_video_detail',
        name: 'topic_video_detail',
        component: topic_video_detail,
        meta:{
            title: '视频基本信息',
            requiresAuth: false,
            keepAlive: true
        },
    },

    {
        path: '/fvr',
        name: 'fvr',
        component: fvr,
        meta:{
            title: 'VR展览馆',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/photo_grapher',
        name: 'photo_grapher',
        component: photo_grapher,
        query:{catid:0},
        meta:{
            title: '摄影师列表',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/photo_grapher_album',
        name: 'photo_grapher_album',
        component: photo_grapher_album,
        meta:{
            title: '摄影师专辑',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/photo_grapher_detail',
        name: 'photo_grapher_detail',
        component: photo_grapher_detail,
        meta:{
            title: '摄影师作品',
            requiresAuth: false,
            keepAlive: true
        },
    },
    {
        path: '/my/home',
        name: 'home',
        component: home,
        meta:{
            title: '个人中心首页',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/recharge',
        name: 'recharge',
        component: recharge,
        meta:{
            title: '收益中心',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/work_manage',
        name: 'work_manage',
        component: work_manage,
        meta:{
            title: '作品管理',
            requiresAuth: true,
            keepAlive: true
        },
    },
    //摄影师供稿
    {
        path: '/my/apply_work',
        name: 'apply_work',
        component: apply_work,
        meta:{
            title: '摄影师供稿',
            requiresAuth: true,
            keepAlive: true
        },
    },
    // 入住信息
    {
        path: '/my/apply_info',
        name: 'apply_info',
        component: apply_info,
        meta:{
            title: '入驻信息',
            requiresAuth: true,
            keepAlive: true
        },
    },
    // 供稿合同签约
    {
        path: '/my/apply_contract',
        name: 'apply_contract',
        component: apply_contract,
        meta:{
            title: '供稿合同签约',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/application',
        name: 'application',
        component: application,
        meta:{
            title: '入驻申请',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/apply_pass',
        name: 'apply_pass',
        component: apply_pass,
        meta:{
            title: '入驻申请通过',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/apply_nopass',
        name: 'apply_nopass',
        component: apply_nopass,
        meta:{
            title: '入驻申请未通过',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/no_settled',
        name: 'no_settled',
        component: no_settled,
        meta:{
            title: '未入驻提示页',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/apply_passing',
        name: 'apply_passing',
        component: apply_passing,
        meta:{
            title: '申请审核中',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/contract',
        name: 'contract',
        component: contract,
        meta:{
            title: '图片购买合同',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/setting',
        name: 'setting',
        component: setting,
        meta:{
            title: '账号设置',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/cart',
        name: 'cart',
        component: cart,
        meta:{
            title: '购物车',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/wallet',
        name: 'wallet',
        component: wallet,
        meta:{
            title: '我的钱包',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/auth_records',
        name: 'auth_records',
        component: auth_records,
        meta:{
            title: '授权记录',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/favorites',
        name: 'favorites',
        component: favorites,
        meta:{
            title: '我的收藏',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/order_list',
        name: 'order_list',
        component: order_list,
        meta:{
            title: '订单列表',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/order_detail',
        name: 'order_detail',
        component: order_detail,
        meta:{
            title: '订单详情',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/order_pay',
        name: 'order_pay',
        component: order_pay,
        meta:{
            title: '订单支付确认页',
            requiresAuth: true,
            keepAlive: true
        },
    },
    {
        path: '/my/pay_qrcode',
        name: 'pay_qrcode',
        component: pay_qrcode,
        meta:{
            title: '订单支付页',
            requiresAuth: true,
            keepAlive: true
        },
    },
    // { 
    //     path: '/:pathMatch(.*)*', 
    //     name: 'NotFound', 
    //     component: NotFound 
    // },
];

const router = createRouter({
    history: createWebHistory(), //createWebHashHistory(),//
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },


});

export default router;
