import { 
  register,
  login,
  addCart,
  CartList,
  Pay,
  refreshToken,
  AuthList,
  deleteCartGoods,
  clearCartGoods,
  BatchDeleteCartGoods,
  RechargeOrder,
  BalanceRecords,
  Wallet,
  modifyPassword,
  PersonInfo,
  ChargePay,
  BalanceRecordInfo,
  OrderList,
  PreCreate,
  loginQrcode,
  QrcodeTicket,
  SubmitContract,
  ContractDetail,
  ContractFormData,
  UploadEsign,
  ContractDownload,
  GrapherWorkerUpload,
  GrapherWorkerForm,
  ApplyInfo,
  PostApplyContract,
  GetApplyContract,
  UploadCameraEsign,
  PostImgdata,
  AddSubject,
  PostVideoData,
  CameraWorkList,
  CameraRechargeOrder,
  CameraRechargeLog,
  CameraRecharge,
  UserSubjectList,
  CameraRechargeInfo,
  PostCollect,
  ActivityList,
  UploadBase64File,
  ActivityDetail,
  ImagePackages,
  getOneCollects,
  getCollectDetail,
  CollectList,
  CameraWorkDetail,
  CameraWorkUpdate,
  getCollectUpdate,
  CameraWorkOffsale,
  CameraWorkDelete,
  FolderList,
  AddFolder,
  EditFolder,
  FolderDetail,
  DeleteFolder,
  PostPV,
  GetPV,
  DeleteCollect,
  UploadIdcardFile,
  MobileCodeLogin,
  MobileCode,
  checkSimilarImage,

} from '@/api/user';

import {
  setToken,
  setUsername,
  getToken,

  getUsername,
  removeToken,
  removeUsername,

  setTokentype,
  getTokentype,
  removeTokentype,

  setAuthKey,
  getAuthKey,
  removeAuthKey,

  setMobile,
  getMobile,
  removeMobile,

  getUrl,
  setUrl,
  removeUrl,

  getKey,
  setKey,
  removeKey,

  getTicketKey,
  setTicketKey,
  removeTicketKey,

  getExpireSeconds,
  setExpireSeconds,
  removeExpireSeconds,

} from "@/utils/cookies";
import * as utils from "/src/utils/utils";
// import { useCookies } from "vue3-cookies";
// const { cookies } = useCookies();

const state = {
    infos:"用户中心",
  	userInfo:{
      username: "" || getUsername(),
      mobile: "" || getMobile(),
  		access_token: "" || getToken(),
      token_type: "" || getTokentype(),
      expires_in: 0,
      login_time:0,
  	},
    qrcodeInfo:{
      expire_seconds: 0 || 0,
      key: '',
      ticket: '',
      login_time: 0,
    },
  	count: 0,
    cartList:[],
    tokenNeedRefresh: false,
    isAuthenticated:false || getAuthKey(),
}
// 相当于事件
const mutations = {
	increment (state) {
    // 这里的 `state` 对象是模块的局部状态
    state.count++
  },
  userInfo(state, data={}) {
    state.userInfo = data;
  },
  isRefreshToken(state,data=false){
    state.tokenNeedRefresh = data;
  },
  //设置token
  SET_TOKEN(state, data={}) {
    state.userInfo.access_token = data.access_token;
    state.userInfo.expires_in = data.expires_in;
    state.userInfo.username = data.username;
    state.userInfo.mobile = data.mobile;
    state.userInfo.token_type = data.token_type;
    var timestamp = new Date().getTime();
    state.userInfo.login_time = Math.floor(timestamp / 1000);
    // localStorage.setItem('access_token', data.access_token );
    // localStorage.setItem('login_time', Math.floor(timestamp / 1000) );
    // localStorage.setItem('expires_in', data.expires_in );
    
    Object.keys(data).length > 0 && setToken(data) && setUsername(data) && setTokentype(data) && setMobile(data); //当value存在时写入token
  },
  SET_AUTHTICATE(state, value, data={}){
    setAuthKey(value, data);
  },
  saveCartList(state, data=[]){
    state.cartList = data;
  },
  RefreshToken(state, data={}){
    Object.keys(data).length > 0 && setToken(data);
  },
  // //设置setTokentype
  // SET_TOKEN_TYPE(state, data={}) {
  //   state.userInfo.token_type = data.token_type;
  //   data.token_type && setTokentype(data); //当value存在时写入token_type
  // },
  // //设置用户名
  // SET_USERNAME(state, data={}) {
  //   state.userInfo.username = value;
  //   value && setUsername(value); //当value存在时写入token
  // },
  // //设置用户名
  // SET_MOBILE(state, data={}) {
  //   value && setMobile(value); //当value存在时写入token
  // },
  SET_LOGIN_COOKIE(state, data={}) {
    state.qrcodeInfo.url = data.url;
    state.qrcodeInfo.expires_in = data.expires_in;
    state.qrcodeInfo.username = data.username;
    state.qrcodeInfo.token_type = data.token_type;
    var timestamp = new Date().getTime();
    state.qrcodeInfo.login_time = Math.floor(timestamp / 1000);

    Object.keys(data).length > 0 && setUrl(data) && setKey(data) && setTicketKey(data); 

  },

  SET_LOGIN_AUTHTICATE(state, value, data={}){
    setQrcodeLoginAuthKey(value, data);
  },

}

const actions = {
	incrementIfOddOnRootSum ({ state, commit, rootState }) {
      if ((state.count + rootState.count) % 2 === 1) {
        commit('increment')
      }
  },
  checkSimilarImage(context, param={}){
      return new Promise((resolve, reject) => {
        checkSimilarImage(param).then((data)=>{        
          console.log(data, "checkSimilarImage"); 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err, "checkSimilarImageErr")
        });
      })
  },
  MobileCodeLogin(context, param={}){
      return new Promise((resolve, reject) => {
        MobileCodeLogin(param).then((data)=>{
          console.log(data,'MobileCodeLogin-data');
          if(data.code == -1){

          }else{
            data.mobile = param.mobile;
            data.access_token = data.auth.access_token;
            data.expires_in = data.auth.expires_in;
            data.token_type = data.auth.token_type;
            // context.commit("userInfo", data);
            context.commit("SET_TOKEN", data);
            // context.commit("SET_TOKEN_TYPE", data);
            // context.commit("SET_USERNAME", data);
            context.commit("SET_AUTHTICATE",true, data);
            
          }
          console.log(data,"login data"); 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"MobileCodeLogin")
        });
      })
  },
  MobileCode(context, param={}){
      return new Promise((resolve, reject) => {
        MobileCode(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"MobileCode")
        });
      })
  },
  UploadIdcardFile(context, param={}){
      return new Promise((resolve, reject) => {
        UploadIdcardFile(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"idcard")
        });
      })
  },
  PostPV(context, param={}){
      return new Promise((resolve, reject) => {
        console.log(param,"parampv");
        PostPV(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"PostPV")
        });
      })
  },
  GetPV(context, param={}){
      return new Promise((resolve, reject) => {
        GetPV(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"GetPV")
        });
      })
  },
  DeleteCollect(context, param={}){
      return new Promise((resolve, reject) => {
        DeleteCollect(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"DeleteCollect")
        });
      })
  },
  DeleteFolder(context, param={}){
      return new Promise((resolve, reject) => {
        DeleteFolder(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"FolderDetail")
        });
      })
  },
  FolderDetail(context, param={}){
      return new Promise((resolve, reject) => {
        FolderDetail(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"FolderDetail")
        });
      })
  },
  EditFolder(context, param={}){
      return new Promise((resolve, reject) => {
        EditFolder(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"EditFolder")
        });
      })
  },
  AddFolder(context, param={}){
      return new Promise((resolve, reject) => {
        AddFolder(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"AddFolder")
        });
      })
  },
  FolderList(context, param={}){
      return new Promise((resolve, reject) => {
        FolderList(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"FolderList")
        });
      })
  },
  CameraWorkDelete(context, param={}){
      return new Promise((resolve, reject) => {
        CameraWorkDelete(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraWorkDelete")
        });
      })
  },
  CameraWorkOffsale(context, param={}){
      return new Promise((resolve, reject) => {
        CameraWorkOffsale(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraWorkOffsale")
        });
      })
  },
  CollectList(context, param={}){
      return new Promise((resolve, reject) => {
        CollectList(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CollectList")
        });
      })
  },
  getCollectUpdate(context, param={}){
      return new Promise((resolve, reject) => {
        getCollectUpdate(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"getCollectUpdate")
        });
      })
  },
  getCollectDetail(context, param={}){
      return new Promise((resolve, reject) => {
        getCollectDetail(param).then((data)=>{ 
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"getCollectDetail")
        });
      })
  },


  getOneCollects(context, param={}){
      return new Promise((resolve, reject) => {
        getOneCollects(param).then((data)=>{ 
          console.log(data,"collect-data");
          let arr = [];
          let obj = {};
          if(data.data.length>0){

            // for (let item of utils.handleImageArr(data.data)){
            //   utils.getImageSize(item.thumb).then((size)=>{
            //     item.ratio_w = size.width;
            //     item.ratio_h = size.height;
            //     arr.push(item);
            //     if(arr.length == data.data.length){
            //       obj.data = arr;
            //       obj.pagination = data.pagination
            //       resolve(obj);
            //     }
            //   })
            // }

            // obj = utils.handleImageInfo(data);
        


            data.data.forEach(async(item,index)=>{
              // (async () => {
                // 这种方式会使得图片顺序不稳定，图片顺序来回变动
                let size = await utils.getImageSize(item.thumb);
                item.ratio_w = size.width;
                item.ratio_h = size.height;
                arr.push(item);
                if(arr.length==data.data.length){
                  obj.data = arr;
                  obj.pagination = data.pagination
                  console.log(obj,'collect-obj');
                  resolve(obj);
                }

                

                // 这种方式会使得图片顺序稳定，图片顺序不会来回变动
                // utils.getImageSize(item.thumb).then((size)=>{
                //   item.ratio_w = size.width;
                //   item.ratio_h = size.height;
                //   arr.push(item);
                //   if(arr.length == data.data.length){
                //     obj.data = arr;
                //     obj.pagination = data.pagination
                //     resolve(obj);
                    
                    
                //   }
                // })
                
              // })();
            })
            
            // resolve(data);

          }
          // else{
          //   resolve(data);
          // }
          
 
          
        }).catch((err)=>{
          reject(err)
          console.error(err,"getOneCollects")
        });
      })
  },
  ImagePackages(context, param={}){
      return new Promise((resolve, reject) => {
        ImagePackages(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"ImagePackages")
        });
      })
  },
  UploadBase64File(context, param={}){
      return new Promise((resolve, reject) => {
        UploadBase64File(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"UploadBase64File")
        });
      })
  },
  ActivityList(context, param={}){
      return new Promise((resolve, reject) => {
        ActivityList(param).then((data)=>{
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"ActivityList")
        });
      })
  },
  ActivityDetail(context, param={}){
      return new Promise((resolve, reject) => {
        ActivityDetail(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"ActivityDetail")
        });
      })
  },
  PostCollect(context, param={}){
      return new Promise((resolve, reject) => {
        PostCollect(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"PostCollect")
        });
      })
  },
  UserSubjectList(context, param={}){
      return new Promise((resolve, reject) => {
        UserSubjectList(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"UserSubjectList")
        });
      })
  },
  CameraWorkUpdate(context, param={}){
      return new Promise((resolve, reject) => {
        CameraWorkUpdate(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraWorkUpdate")
        });
      })
  },
  CameraWorkDetail(context, param={}){
      return new Promise((resolve, reject) => {
        CameraWorkDetail(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraWorkDetail")
        });
      })
  },
  CameraRechargeInfo(context, param={}){
      return new Promise((resolve, reject) => {
        CameraRechargeInfo(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraRecharge")
        });
      })
  },
  CameraRecharge(context, param={}){
      return new Promise((resolve, reject) => {
        CameraRecharge(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraRecharge")
        });
      })
  },
  CameraRechargeLog(context, param={}){
      return new Promise((resolve, reject) => {
        CameraRechargeLog(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraRechargeLog")
        });
      })
  },
  CameraRechargeOrder(context, param={}){
      return new Promise((resolve, reject) => {
        CameraRechargeOrder(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"CameraRechargeOrder")
        });
      })
  },
  CameraWorkList(context, param={}){
      return new Promise((resolve, reject) => {
        CameraWorkList(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"UploadCameraEsign")
        });
      })
  },
  UploadCameraEsign(context, param={}){
      return new Promise((resolve, reject) => {
        UploadCameraEsign(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"UploadCameraEsign")
        });
      })
  },
  AddSubject(context, param={}){
      return new Promise((resolve, reject) => {
        AddSubject(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"AddSubject")
        });
      })
  },
  PostVideoData(context, param=[]){
      return new Promise((resolve, reject) => {
        PostVideoData(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"PostVideoData")
        });
      })
  },
  PostImgdata(context, param=[]){
      return new Promise((resolve, reject) => {
        PostImgdata(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"PostImgdata")
        });
      })
  },
  /**
    * 获取合同信息
    */
  GetApplyContract(context, param={}){
      return new Promise((resolve, reject) => {
        GetApplyContract(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"GetApplyInfo")
        });
      })
  },
  /**
   * 供稿合同签约
   */
  PostApplyContract(context, param={}){
      return new Promise((resolve, reject) => {
        PostApplyContract(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"PostApplyInfo")
        });
      })
  },
  ApplyInfo(context, param={}){
      return new Promise((resolve, reject) => {
        ApplyInfo(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err,"ApplyInfo")
        });
      })
  },
  GrapherWorkerForm(context, param={}){
      return new Promise((resolve, reject) => {
        GrapherWorkerForm(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  GrapherWorkerUpload(context, param={}){
      return new Promise((resolve, reject) => {
        GrapherWorkerUpload(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  SubmitContract(context, param={}){
      return new Promise((resolve, reject) => {
        SubmitContract(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  ContractDetail(context, param={}){
      return new Promise((resolve, reject) => {
        ContractDetail(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  ContractFormData(context, param={}){
      return new Promise((resolve, reject) => {
        ContractFormData(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  UploadEsign(context, param={}){
      return new Promise((resolve, reject) => {
        UploadEsign(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  ContractDownload(context, param={}){
      return new Promise((resolve, reject) => {
        ContractDownload(param).then((data)=>{   
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  register(context, param={}){
      return new Promise((resolve, reject) => {
        register(param).then((data)=>{   
          // context.commit("postPics", data);
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  loginQrcode(context, param={}){
      return new Promise((resolve, reject) => {
        if(getUrl()){
          let data = {};
          data.url = getUrl();
          data.expire_seconds = getExpireSeconds();
          data.key = getKey();
          data.ticket = getTicketKey();
          console.log(data,"old-qrcode");
          resolve(data);
        }else{
          loginQrcode(param).then((data)=>{ 
            console.log(data,"qrcode");
            localStorage.setItem('expire_seconds',data.expire_seconds);
            let time = (new Date().getTime())/1000;
            localStorage.setItem('scan_time', time);
            context.commit("SET_LOGIN_COOKIE", data); 
            resolve(data);
          }).catch((err)=>{
            reject(err)
            console.error(err)
          });
        }
        
      })
  },
  QrcodeTicket(context, param={}){
      return new Promise((resolve, reject) => {
        QrcodeTicket(param).then((data)=>{ 
          if(data.status == 4){
            data.username = data.user.username;
            data.mobile = data.user.mobile;
            context.commit("SET_TOKEN", data);
            context.commit("SET_AUTHTICATE",true, data);
          }
          
          resolve(data);
        }).catch((err)=>{
          reject(err)
          console.error(err)
        });
      })
  },
  login(context, param={}){
    console.log(param,"param");
    return new Promise((resolve, reject) => {
      login(param).then((data)=>{
        console.log(data,"data");  
        data.username = param.username;
        data.mobile = param.mobile;
        context.commit("SET_TOKEN", data);
        context.commit("SET_AUTHTICATE",true, data);
        // console.log(data,"login data");
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  getPersonInfo(context, param={}){
    return new Promise((resolve, reject) => {
      PersonInfo(param).then((data)=>{
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  refreshToken(context, param={}){
    return new Promise((resolve, reject) => {
      refreshToken(param).then((data)=>{
        console.log(data,"data"); 
        context.commit("SET_TOKEN", data); 
        // context.commit("SET_TOKEN_TYPE", data);
        context.commit("SET_AUTHTICATE",true, data);       
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  PreCreate(context, param={}){
    return new Promise((resolve, reject) => {
      PreCreate(param).then((data)=>{
        // console.log(data, 'preCreate');
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  OrderList(context, param={}){
    return new Promise((resolve, reject) => {
      OrderList(param).then((data)=>{
        // console.log(data, 'OrderList');
        // context.commit("postPics", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  addCart(context, param={}){
    return new Promise((resolve, reject) => {
      addCart(param).then((data)=>{
        console.log(data, 'cartdata');
        // context.commit("postPics", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  getCartList(context, param={}){
    return new Promise((resolve, reject) => {
      CartList(param).then((data)=>{
        if(data.length>0){
          data.map((item)=>{
            item.checked = false;
            for(let j=0;j<item.image.option_all.length;j++){
              item.image.option_all[j].id = j;
              item.image.option_all[j].isChecked = false;
              for(let i=0;i<item.image.option.length;i++){
                if(item.image.option[i]==j){
                  item.image.option_all[j].isChecked = true;
                  break;
                }
              }
            }
            return item;
          })
        
        }
        context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  deleteCartGoods(context, param={}){
    return new Promise((resolve, reject) => {
      deleteCartGoods(param).then((data)=>{
        console.log(data,"data");
        if(data.length > 0){
          data.map((item)=>{
            item.checked = false;
            for(let j=0;j<item.image.option_all.length;j++){
              item.image.option_all[j].id = j;
              item.image.option_all[j].isChecked = false;
              for(let i=0;i<item.image.option.length;i++){
                if(item.image.option[i]==j){
                  item.image.option_all[j].isChecked = true;
                  break;
                }
              }
            }
            return item;
          })
        }
        
        context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  BatchDeleteCartGoods(context, param={}) {
    console.log(param,"BatchDeleteCartGoods");
    return new Promise((resolve, reject) => {
      BatchDeleteCartGoods(param).then((data)=>{
        console.log(data,"data");
        if(data.length > 0){
          data.map((item)=>{
            item.checked = false;
            for(let j=0;j<item.image.option_all.length;j++){
              item.image.option_all[j].id = j;
              item.image.option_all[j].isChecked = false;
              for(let i=0;i<item.image.option.length;i++){
                if(item.image.option[i]==j){
                  item.image.option_all[j].isChecked = true;
                  break;
                }
              }
            }
            return item;
          })
        }
        
        context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  clearCartGoods(context, param={}){
    return new Promise((resolve, reject) => {
      clearCartGoods(param).then((data)=>{
        console.log(data,"data");
        context.commit("saveCartList", []);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  getPay(context, param={}){
    console.log(context,'ctx');
    return new Promise((resolve, reject) => {
      Pay(param).then((data)=>{
        // console.log(data,"pay");
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  getAuthList(context, param={}){
    return new Promise((resolve, reject) => {
      AuthList(param).then((data)=>{
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  RechargeOrder(context, param={}){
    return new Promise((resolve, reject) => {
      RechargeOrder(param).then((data)=>{
        // console.log(data,"recharge")
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  ChargePay(context, param={}){
    return new Promise((resolve, reject) => {
      ChargePay(param).then((data)=>{
        // console.log(data,"ChargePay")
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  BalanceRecords(context, param={}){
    return new Promise((resolve, reject) => {
      BalanceRecords(param).then((data)=>{
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  BalanceRecordInfo(context, param={}){
    return new Promise((resolve, reject) => {
      BalanceRecordInfo(param).then((data)=>{
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  Wallet(context, param={}){
    return new Promise((resolve, reject) => {
      Wallet(param).then((data)=>{
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },
  modifyPassword(context, param={}){
    return new Promise((resolve, reject) => {
      modifyPassword(param).then((data)=>{
        // context.commit("saveCartList", data);
        resolve(data);
      }).catch((err)=>{
        reject(err)
        console.error(err)
      });
    })
  },



}

const getters = { 
	doubleCount (state) {
      return state.count * 2
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}