<template>
	<PageLoading :is-loading="isLoading"></PageLoading>
	<SideComponent @scrollto="scroll"></SideComponent>
	<div class="container" ref="viewBox">
		<!-- 以下代码与index-pic是一套 -->
		<div class="header-container">
			<div class="search-swiper-container">				
				<Swiper :list="story_sliders"/>
				<!-- <search-bar2></search-bar2> -->
			</div>
		</div>
		
		<div class="content">
			<div class="news-container">
				<section id="news-wrapper" class="news-wrapper">					
					<div class="tale-caption">
						<div class="tale-span" style="margin-bottom:10px;">
							<span>图文</span>
						</div>
					</div>
					<div class="news-right">
						<Loading :is-loading="isPicLoading"></Loading>
						<div class="news-item" v-for="item in picList" @click="toDetail(item.id)">  
							<div class="news-item-outer">
								<div class="img-outer">
									<img :src="item.thumb" oncontextmenu="return false" :alt="item.title"/>
								</div>
								<div class="news-title">
									<span>{{item.title}}</span>
								</div>
							</div>
						</div>	
					</div>
					
					<!-- <div class="page-wrapper">
						<el-pagination 
						    background 
						    layout="prev, pager, next" 
						    :total="pagination.total" 
						    v-model:current-page="pagination.current"
						    v-model:page-size="pagination.pageSize"	    
						/>
					</div> -->
				</section>
				<div class="loadmore">
					<Loading :is-loading="isPicLoading"></Loading>
					<span class="more" @click="loadMore()" v-if="!picNomore">{{$t('loadmore')}}</span>
					<span class="end" v-if="picNomore">- {{$t('theEnd')}} -</span>
				</div>
			</div>

			<div class="news-container">
				<section id="news-wrapper" class="news-wrapper">
					<div class="tale-caption">
						<div class="tale-span" style="margin-bottom:10px;">
							<span>视频</span>
						</div>
					</div>
					<div class="news-right">
						<Loading :is-loading="isVideoLoading"></Loading>
						<div class="news-item" v-for="item in videoList">
							<div class="news-item-outer">
								<div class="img-outer">
									<div @click="toVideoDetail(item.id)" style="width:100%;height:100%;">
										<video v-if="isMobile"
										    :poster="item.thumb"
										    preload="none" 
										    muted=""
										    :alt="item.title" 									    
										    controlslist="nodownload"
										    oncontextmenu="return false">
											<track kind="captions" />
											<source :src="item.url" type="video/mp4">
										</video>
										<video v-if="!isMobile"
											controls
										    :poster="item.thumb"
										    preload="none" 
										    muted=""
										    :alt="item.title" 									   
										    controlslist="nodownload"
										    oncontextmenu="return false"
										    :src="item.url"
										    
										    >
										    <!-- @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" -->
											<track kind="captions" />
											<!-- <source :src="item.url" type="video/mp4"> -->
										</video>
									</div>
								</div>
								<div class="news-title">
									<span>{{item.title}}</span>
								</div>
							</div>
						</div>	
					</div>
					
					<!-- <div class="page-wrapper">
						<el-pagination 
						    background 
						    layout="prev, pager, next" 
						    :total="pagination.total" 
						    v-model:current-page="pagination.current"
						    v-model:page-size="pagination.pageSize"	    
						/>
					</div> -->
				</section>
				<div class="loadmore">
					<Loading :is-loading="isVideoLoading"></Loading>
					<span class="more" @click="loadMore()" v-if="!videoNomore">{{$t('loadmore')}}</span>
					<span class="end" v-if="videoNomore">- {{$t('theEnd')}} -</span>
				</div>
			</div>

		</div>
		<Footer/>
	</div>
</template>

<script>
	import tale from './tale_controller';
	export default tale;
</script>

<style src="@/assets/css/tale.css" scoped></style>