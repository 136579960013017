import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, onMounted, nextTick, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    
		const data = reactive({
			isMobile: false,
			isLoading:true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			imageSrc: '',
			//定义一个imageNew变量来接收裁剪之后的图片
			imageNew: '',
			picCats: [

      		],
      		videos:[],
		    videoDetail:{},
		    page:1,
      		nomore:true,
      		keyword:'',
      		isVideoLoading: true,
		});

		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const outerStyleStr = ref("position:relative;left:10%;top:40%;transform:translate(-10%,-40%);width:auto;");
		provide('outerStyleStr', outerStyleStr);

		const styleStr = ref("height:45px;");
		provide('styleStr', styleStr);

		const showSearchText = ref(false);
		provide('showSearchText', showSearchText);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		//点击裁剪按钮
		const updateImageSrc = (updateImageSrc) => {
		  	imageNew.value = updateImageSrc
		}
		const store = useStore();		
	    const getVideoList = (keyword='',page=1) => {
	    	data.keyword = keyword;
        	store.dispatch("common/getPicLists",{
	        	page: page,
	        	filter: keyword,
	        	type: 2,
	        }).then((res) => {
	        	if(res.data.length > 0){
	        		if(page == 1){
	        			data.videos = res.data;
	        		}else{
	        			data.videos = data.videos.concat(res.data);
	        		}
					
					if(res.data.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}
				}else{
					data.nomore = true;
				}        
	        	// data.pagination = res.pagination;
	        	data.isVideoLoading = false;
	        	data.page += 1;
	        }).catch(err=>{
	        	data.isVideoLoading = false;
	        })
        }
        
	    
      	const id = route.query.id;
      	const getVideo = (id=0) => {
      		store.dispatch("common/TaleDetail",{
	        	id:id,
	        }).then((res) => {
	        	console.log(res,"video");
	        	data.videoDetail = res
	        	data.videoDetail.size = proxy.$utils.round(data.videoDetail.size / 1024 / 1024,2);
	        	if(data.videoDetail.keywords == null){
	        		data.videoDetail.keywords = [];
	        	}
	        	bus.emit('showFooter',true);
	        })
      	}

      	
				
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

		onMounted(async()=>{
			// getVideoList();
			getVideo(id);
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		
		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			updateImageSrc,
			getVideo,
			getVideoList,
			viewBox,
			scroll,
			
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			if(document.documentElement.clientWidth < 768){
				this.isMobile = true;
			}else{
				this.isMobile = false;
			}
		},
		activeStyle(item) {		
			if(item.ratio_w == null || item.ratio_h == null || item.ratio_w == "" || item.ratio_h == "") {
				(async () => {
				    let size = await this.$utils.getImageSize(item.thumb);
				    console.log(size,"size");
				    item.ratio_w = size.width;
				    item.ratio_h = size.height;
				})();

			}
			let object = {};
			object.width = item.ratio_w*200/item.ratio_h+'px';

			return object;
		},
		handleMouseEnter(event) {
	      // console.log(event.target,'鼠标移入');
	      event.target.play();
	    },
	    handleMouseLeave(event) {
	      // console.log(event.target,'鼠标移出');
	      event.target.pause();
	    },
		loadMore() {
			this.isVideoLoading = true;
			this.getVideoList(this.keyword, this.page);
		},
		changeVideoinfo(id){
			let that = this;
			this.getVideo(id);
			// this.$store.dispatch("common/getPicVideoDetail",{
	  //       	id:id,
	  //       }).then((res) => {
	  //       	console.log(res,"video1");
	  //       	that.videoDetail = res
	  //       })
			// this.$router.push({name:'topic_video_detail',params:{id:id}})
		},
		realTime(data) {
			console.log(data,"data");
  			var previews = data;
  			this.previews = data
  		},
  		/*
		  loadeddata 事件在媒体当前播放位置的视频帧（通常是第一帧）加载完成后触发
		  preload为none时不会触发
		*/
		// getPoster () { // 在未设置封面时，自动截取视频0.5s对应帧作为视频封面
		//   // 由于不少视频第一帧为黑屏，故设置视频开始播放时间为0.5s，即取该时刻帧作为封面图
		//   veo.value.currentTime = props.second
		//   // 创建canvas元素
		//   const canvas = document.createElement('canvas')
		//   const ctx = canvas.getContext('2d')
		//   // canvas画图
		//   canvas.width = veo.value.videoWidth
		//   canvas.height = veo.value.videoHeight
		//   ctx?.drawImage(veo.value, 0, 0, canvas.width, canvas.height)
		//   // 把canvas转成base64编码格式
		//   veoPoster.value = canvas.toDataURL('image/png')
		// },

	},
})