import { defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, nextTick, onMounted, onBeforeUpdate } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import {useStore} from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBar2 from '@/components/layout/SearchBar2.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		PageLoading,
		Header2,
		Menu,
	    Swiper,
	    SearchBar2,
	    Footer,
	    SideComponent,
	    Loading,
	},
	props: {

	},
	setup() {
		provide('logoColor', 'black');
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    console.log(route,"route");
		const data = reactive({
			isLoading: true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			picList:[],
			videoList:[],
			pagination:{},
			banners:[

      		],
      		picPage:1,
      		videoPage:1,
      		picNomore:true,
      		videoNomore:true,
      		keyword: '',
      		maxHeight:1080,
      		isChangeData: false,
      		isPicLoading: true,
      		siteInfo:{},
			isVideoLoading:false,
			type: 1,
			isMobile: false,
			story_sliders:[],
		});

		const showMenu = ref(proxy.$menus);
		provide('showMenu', showMenu);

		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();
		// data.banners = store.state.common.siteInfo.sliders;
        const getSiteInfo = () => {
		    return new Promise((resolve, reject) => {
		    	setTimeout(()=>{
			      store.dispatch("common/fetchSiteInfo").then((res) => {
			        data.story_sliders = res.story_sliders;
			        			        
			        console.log(data.story_sliders,"siteInfo");
			        resolve();
			        bus.emit('isLoading',false);
			      }).catch(err => {
			        reject();
			      })
			  },200)
		    })
	    
	    }

        const talePicList = (page=1, size=12) => {
        	data.picPage = page;
        	store.dispatch("common/TaleList",{
        		type: 1,
        		page: page,
        		size: size,
        	}).then((res) => {
	        	if(res.data.length > 0){
	        		if(page == 1) {
	        			data.picList = res.data;
	        		} else {
	        			data.picList = data.picList.concat(res.data);
	        		}
					
					if(res.data.length < res.pagination.pageSize) {
						data.picNomore = true;
					} else {
						data.picNomore = false;
					}
				}else{
					data.picNomore = true;
				}        
	        	
	        	console.log(data.picList,"data.picList");
        		data.picPage += 1;
        		data.isPicLoading = false;
	        	
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        })
        }
        const taleVideoList = (page=1, size=12) => {
        	data.videoPage = page;
        	store.dispatch("common/TaleList",{
        		type: 2,
        		page: page,
        		size: size,
        	}).then((res) => {
	        	if(res.data.length > 0){
	        		if(page == 1){
	        			data.videoList = res.data;
	        		} else {
	        			data.videoList = data.videoList.concat(res.data);
	        		}
					
					if(res.data.length < res.pagination.pageSize) {
			        	data.videoNomore = true;			        	
					} else {
			        	data.videoNomore = false;
					}
				}else{
		        	data.videoNomore = true;
				}        
	        	
	        	console.log(data.videoList, "data.videoList");
        		data.videoPage += 1;
        		data.isVideoLoading = false;
	        	        	
	        	bus.emit('showFooter',true);
	        }).catch(err=>{
	        	data.isVideoLoading = false;
	        })
        }
        const waterfallHandler = async () => {
			await nextTick(()=>{
			    // const imgWidth = data.imgWidth + data.imgMargin * 2;
			    const contentW = document.getElementById("newslist").offsetWidth;
			    
			    setTimeout(()=>{
				    const imgList = document.getElementsByClassName("news-item");
				    if(imgList.length > 0){
				    	const imgWidth = imgList[0].offsetWidth - 5;
				    
					    // 获取图片的列数
					    const column = parseInt(contentW / imgWidth);
					    
					    // 高度数组
					    const heightArr = new Array(column).fill(0);
					    
					    for (let i = 0; i < imgList.length; i++) {
					        const item = imgList[i];
					        
					        // 当前元素的高度
					        const itemHeight = item.clientHeight - 5;
					        
					        // 高度数组最小的高度
					        const minHeight = Math.min(...heightArr);
					        // 高度数组最小的高度的索引
					        const minIndex = heightArr.indexOf(minHeight);
					        item.style.position = "absolute";
					        item.style.top = minHeight + "px";
					        item.style.left = minIndex * imgWidth + "px";
					        data.maxHeight = Math.max(...heightArr);
					        data.maxHeight += 150;
					        heightArr[minIndex] += itemHeight;
					        
					    }
				    }
				    
				},200);
			})
		}
        
        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        		        
			}
		}

	    onMounted(async()=>{
	    	await getSiteInfo();
	    	await talePicList(data.picPage);
	    	await taleVideoList(data.videoPage);
	    	
        	setTimeout(() => {
    			// if(document.documentElement.clientWidth > 768){
				//     waterfallHandler();
				// }
				data.isLoading = false;
		        // data.isPicLoading = false;
		        // data.isVideoLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			viewBox,
			scroll,
			waterfallHandler,
			getSiteInfo,
			talePicList,
			taleVideoList,
		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
			if(document.documentElement.clientWidth < 768){
				this.isMobile = true;
			}else{
				this.isMobile = false;
			}
		},
		loadMore() {
			let that = this;
			this.isPicLoading = true;
			this.searchNews(this.keyword, this.page);
		},
		toVideoDetail(id){
			this.$router.push({ path:'/tale_video',query:{id:id} });
		},

		toDetail(id){
			this.$router.push({ name:'tale_detail', query:{ id: id } });
			// let href = this.$router.resolve({ //使用resolve
		 //      name:'tale_detail',    //这里是跳转页面的name
		 //      path: '/tale_detail',
		 //      query:{ id: id },
		 //    })
		 //    window.open(href.href, '_blank')
		},
		activeStyle(item) {
			// let exif = JSON.parse(item.exif);
			// // console.log(item,"item");
			// let keys = null;
			// let key = '';
			// let data = '';
			// if(exif!=null){
			// 	keys = Object.keys(exif);
			// 	key = keys[0];
			// 	data = exif[key];
			// }
			
			// if(data.ratio_w != undefined && data.ratio_h != undefined) {
			// 	console.log(data,"data");
			// 	(async () => {
			// 		if(item.thumb){
			// 			let size = await this.$utils.getImageSize(item.thumb);
			// 		    console.log(size,"size");
			// 		    data.ratio_w = size.width;
			// 		    data.ratio_h = size.height;
			// 		}
				    
			// 	})();

			// }
			let object = {width:0};
			object.width = item.thumb_width*270/item.thumb_height+'px';
			
			return object;
		},
	},
})